import React, { useContext, useState, useEffect } from 'react';
import {
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Row,
	Col,
	Button,
} from 'reactstrap';

import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';

import './signUp.css';

import { useHistory, Redirect } from "react-router-dom";
import TenantContext from '../../utils/tenantContext';


const API_URL = process.env.REACT_APP_API_URL_BASE;
const X_API_KEY = process.env.REACT_APP_X_API_KEY;

const sidebarBackground = {
	backgroundRepeat: "no-repeat",
	backgroundPosition: "bottom center"
};

const linkStyle = {
	fontWeight: 600,
	color: "#2bcfe0"
};

const SignUp = () => {

  const history = useHistory();
  const {tenant} = useContext(TenantContext);
  const [signUpEnabled, signUpEnabledSET] = useState(false);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
	if(Object.entries(tenant).length > 0) {
		const hasSignUpEnabled = tenant.hasOwnProperty('self_signup') && tenant.self_signup;
		if(hasSignUpEnabled) {
			signUpEnabledSET(true);
		} else {
			setRedirect(true);
		}
	}
}, [tenant]);


  return (
	<>
		{redirect ? <Redirect to="/authentication/login" /> : null}
		{
			signUpEnabled &&
			<div className="auth-wrapper d-flex align-items-center" style={sidebarBackground}>
				<div className="container">
					<div id="signupform">
						<Row className="no-gutters justify-content-center">
							{/* <Col md="6" lg="4" className="bg-primary text-white">
								<div className="p-4">
									<h2 className="display-5">Hi,<br /> 
									<span className="text-cyan font-bold">let's send some loud music out there</span></h2>
								</div>
							</Col> */}
							<Col md="6" lg="4" className="bg-white">
								<div className="p-4">
									<h3 className="font-medium mb-3">Register Your Account</h3>
									<Formik
										initialValues={{
											first_name: '',
											last_name: '',
											email: '',
											password: '',
											confirm_password: '',
											acceptTermsOfUsage: false,
											acceptPrivacyPolicy: false,
											acceptCookiePolicy: false,
										}}
										validationSchema={Yup.object().shape({
											first_name: Yup.string().required('First Name is required'),
											last_name: Yup.string().required('Last Name is required'),
											email: Yup
											.string().required('Email is required').email('Email is invalid'),
											password: Yup
											.string()
											.required('Please enter your password')
											.matches(
												/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
												"Password must contain atleast 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
											),
											confirm_password: Yup.string().oneOf([Yup.ref("password"), null], "Password and Confirm password must match").required('Please confirm your password'),
											acceptTermsOfUsage: Yup.bool().oneOf([true], 'Accepting Terms of Usage is required'),
											acceptPrivacyPolicy: Yup.bool().oneOf([true], 'Accepting Privacy Policy is required'),
											acceptCookiePolicy: Yup.bool().oneOf([true], 'Accepting Cookie Policy is required'),
										})}
										onSubmit={({ first_name, last_name, email, password }, { setStatus, setSubmitting }) => {
											setStatus();

											const payload = {
												first_name: first_name,
												last_name: last_name,
												email: email,
												password: password,
											};

											const options = {
												method: "POST",
												mode: 'cors',
												headers: {
												"x-api-key": X_API_KEY,
												"Content-Type": "application/json"
												},
											};

											axios.post(`${API_URL}user-register/`, payload, options).then(res => {
												if (res.status === 201) {
													history.push('/authentication/signup-successfull');
												}
												else if (res.status === 400)
												{
													let error  = res.data.data.message;
													console.log(error);
													setSubmitting(false);
													setStatus('Something went wrong. Please try again.');
												}
												else {
													setSubmitting(false);
													setStatus('Something went wrong. Please try again.');
												}
											}).catch(function (error) {
												if (error.response) {
													if(error.response.status === 400)
													{
														let errorMsg = "";
														let errorData = error.response.data;
														if (errorData.hasOwnProperty("email")) {
															errorMsg = errorData["email"];
														}
														else if (errorData.hasOwnProperty("message"))
														{
															errorMsg = errorData["message"];
														}
														else {
															errorMsg = "Something went wrong. Please try again.";
														}

														setSubmitting(false);
														setStatus(errorMsg);
													}
													else {
														setSubmitting(false);
														setStatus('Something went wrong. Please try again.');
													}
												} else if (error.request) {
													setSubmitting(false);
													setStatus('Something went wrong. Please try again.');
												} else {
													setSubmitting(false);
													setStatus('Something went wrong. Please try again.');
												}
											});
										}}
										render={({ errors, status, touched, isSubmitting }) => (
											<Form className="mt-3" id="signup-form">
												<InputGroup className="mb-3">
													<InputGroupAddon addonType="prepend">
														<InputGroupText>
															<i className="ti-user"></i>
														</InputGroupText>
													</InputGroupAddon>

													<Field name="first_name" placeholder="First Name" type="text" className={'form-control' + (errors.first_name && touched.first_name ? ' is-invalid' : '')} />
													<ErrorMessage name="first_name" component="div" className="invalid-feedback" />
												</InputGroup>
												<InputGroup className="mb-3">
													<InputGroupAddon addonType="prepend">
														<InputGroupText>
															<i className="ti-user"></i>
														</InputGroupText>
													</InputGroupAddon>

													<Field name="last_name" placeholder="Last Name" type="text" className={'form-control' + (errors.last_name && touched.last_name ? ' is-invalid' : '')} />
													<ErrorMessage name="last_name" component="div" className="invalid-feedback" />
												</InputGroup>
												<InputGroup className="mb-3">
													<InputGroupAddon addonType="prepend">
														<InputGroupText>
															<i className="ti-email"></i>
														</InputGroupText>
													</InputGroupAddon>

													<Field name="email" placeholder="Email" type="text" className={'form-control' + (errors.email && touched.email ? ' is-invalid' : '')} />
													<ErrorMessage name="email" component="div" className="invalid-feedback" />
												</InputGroup>
												<InputGroup className="mb-3">
													<InputGroupAddon addonType="prepend">
														<InputGroupText>
															<i className="ti-lock"></i>
														</InputGroupText>
													</InputGroupAddon>
													<Field name="password" placeholder="Password" type="password" className={'form-control' + (errors.password && touched.password ? ' is-invalid' : '')} />
													<ErrorMessage name="password" component="div" className="invalid-feedback" />

												</InputGroup>
												<InputGroup className="mb-3">
													<InputGroupAddon addonType="prepend">
														<InputGroupText>
															<i className="ti-lock"></i>
														</InputGroupText>
													</InputGroupAddon>
													<Field name="confirm_password" placeholder="Confirm Password" type="password" className={'form-control' + (errors.confirm_password && touched.confirm_password ? ' is-invalid' : '')} />
													<ErrorMessage name="confirm_password" component="div" className="invalid-feedback" />

												</InputGroup>

												<div className="form-group">
													<div className="form-check form-check-inline">
														<Field type="checkbox" name="acceptTermsOfUsage" className={'form-check-input' + (errors.acceptTermsOfUsage && touched.acceptTermsOfUsage ? ' is-invalid' : '')}  />
														<label className="form-check-label" htmlFor="acceptTermsOfUsage">
															I accept the &nbsp;<a style={linkStyle} href={'https://www.iubenda.com/terms-and-conditions/51840490'} target='_blank' rel='noopener noreferrer'>Terms of Usage</a>
															<ErrorMessage name="acceptTermsOfUsage" component="div" className="invalid-feedback" />
														</label>
													</div>

													<div className="form-check form-check-inline">
														<Field type="checkbox" name="acceptPrivacyPolicy" className={'form-check-input' + (errors.acceptPrivacyPolicy && touched.acceptTermsOfUsage ? ' is-invalid' : '')} />
														<label className="form-check-label" htmlFor="acceptPrivacyPolicy">
															I accept the &nbsp;<a style={linkStyle} href={'https://www.iubenda.com/privacy-policy/51840490/full-legal'} target='_blank' rel='noopener noreferrer'>Privacy Policy</a>
															<ErrorMessage name="acceptPrivacyPolicy" component="div" className="invalid-feedback" />
														</label>
													</div>

													<div className="form-check form-check-inline">
														<Field type="checkbox" name="acceptCookiePolicy" className={'form-check-input' + (errors.acceptCookiePolicy && touched.acceptCookiePolicy ? ' is-invalid' : '')} />
														<label className="form-check-label" htmlFor="acceptCookiePolicy">
															I accept the &nbsp;<a style={linkStyle} href={'https://www.iubenda.com/privacy-policy/51840490/cookie-policy'} target='_blank' rel='noopener noreferrer'>Cookie Policy</a>
															<ErrorMessage name="acceptCookiePolicy" component="div" className="invalid-feedback" />
														</label>
													</div>
												</div>

												<Row className="mb-3">
													<Col xs="12">
														<Button id="signup-submit" size="lg" color="primary" type="submit" block disabled={isSubmitting}>Sign Up</Button>
													</Col>
												</Row>

												{status &&
													<div className={'alert alert-danger new-line'}>{status}</div>
												}

												<div className="justify-content-center d-flex align-items-center">
													Signed Up Already?
													<a
														href="/authentication/login"
														className="forgot text-cyan float-right"
														style={{paddingLeft: 6}}
													>Login</a>
												</div>
											</Form>
										)}
									/>
								</div>
							</Col>
						</Row>
					</div>
				</div>
			</div>
		}
	</>
  );
};

export default SignUp;


